import httpclient from '../../httpclient';
import messages from '../../../assets/messages';
import { commitResponseToStore, httpRequestErrorHandling, validateParams } from '../utils';
import { state } from './state';
import manifest from '../../../manifest';
import authService from '../../../auth/AuthService';

const {
  features: {
    pushNotificationForDocUpload
  }
} = manifest;

export const actions = {
  async fetchBusinessEntities({ commit }) {
    try {
      const response = await httpclient.get('/api/v3/business-entities');
      const results = response.data.results;
      commit('setBusinessEntities', results);
    } catch (e) {
      httpRequestErrorHandling(e, commit);
    }
  },

  async createAddressContactJourney({ commit }, { entity, journey, recordUuid, recordType, businessEntityUuid, accountUuid, orderUuid, orderTaskUuid }) {

    try {

      if (!businessEntityUuid || !orderTaskUuid || !orderUuid || !accountUuid || !recordUuid || !recordType) {
        httpRequestErrorHandling(new Error('AddressContactJourney data missing'), commit, messages.createAddressContactJourney);
        return;
      }

      const tokens = journey === 'address' && entity === 'address'
        ? { addressUuid: recordUuid, addressType: recordType }
        : { contactUuid: recordUuid, contactType: recordType };
      const userUuidInfo = await httpclient.get(`/api/v2/accounts/${accountUuid}/users`);
      const userUuid = (userUuidInfo.data[0].user_uuid);

      if (!userUuid) {
        httpRequestErrorHandling(new Error('createAddressContactJourney user uuid missing '), commit, messages.createAddressContactJourney);
        return;
      }

      const payload = {
        accountUuid,
        userUuid,
        businessEntityUuid,
        orderUuid,
        orderTaskUuid,
        tokens
      };

      const slug = journey === 'address' ? 'simple_address_update_v1' : 'simple_contact_update_v1';

      const response = await httpclient.post(`/journeys/v1/journeys/${slug}/init`, payload);
      if (response.status === 200 || response.status === 201) {
        const orderTaskUuid = payload.orderTaskUuid;
        try {
          const updateResponse = await httpclient.put(`/api/order-tasks/${orderTaskUuid}/status`, {
            status: 'action_req',
            substatus: 'Client Info Pending'
          });
          if (updateResponse.status === 200 || updateResponse.status === 201) {
            commit('setAlertMessage', messages.createAddressContactJourney.success, { root: true });
          }
        } catch (updateError) {
          httpRequestErrorHandling(new Error('createAddressContactJourney'), commit, messages.createAddressContactJourney);
        }
      } else {
        httpRequestErrorHandling(new Error('createAddressContactJourney'), commit, messages.createAddressContactJourney);
      }
    } catch (error) {
      httpRequestErrorHandling(new Error('createAddressContactJourney'), commit, messages.createAddressContactJourney);
    }
  },

  async createCouponJourney({ commit }, { journey, businessEntityUuid, accountUuid, orderUuid, orderBillingItemUuid }) {

    try {
      if (!businessEntityUuid || !orderBillingItemUuid || !orderUuid || !accountUuid) {
        httpRequestErrorHandling(new Error('createCouponJourney'), commit, messages.createCouponJourney);
        return;
      }

      const userUuidInfo = await httpclient.get(`/api/v2/accounts/${accountUuid}/users`);
      const userUuid = (userUuidInfo.data[0].user_uuid);

      if (!userUuid) {
        httpRequestErrorHandling(new Error('createCouponJourney'), commit, messages.createCouponJourney);
        return;
      }
      const couponKey = journey === 'registered_agent_service' ? '100_off_ra_renewal' : '100_off_pro_plan_renewal';
      const tokens = { orderBillingItemUuid, couponKey };

      const payload = {
        accountUuid,
        userUuid,
        businessEntityUuid,
        orderUuid,
        tokens
      };
      const slug = journey === 'registered_agent_service' ? 'coupon_ra_v1' : 'coupon_pro_plan_v1';

      const response = await httpclient.post(`/journeys/v1/journeys/${slug}/init`, payload);
      console.log('response', response);
      if (response.status === 200 || response.status === 201) {
        commit('setAlertMessage', messages.createAddressContactJourney.success, { root: true });
      } else {
        httpRequestErrorHandling(new Error('createCouponJourney'), commit, messages.createCouponJourney);
      }
    } catch (error) {
      httpRequestErrorHandling(new Error('createCouponJourney'), commit, messages.createCouponJourney);
    }
  },
  async fetchJourneyForAccount({ commit }, { accountUuid }) {
    if (!accountUuid) {
      throw new Error('Account UUID is required to fetch journey instances');
    }
    try {
      const response = await httpclient.get(`/journeys/v1/journey-instances`, {
        params: { accountUuid }
      });
      return response.data;
    } catch (error) {
      // Handle the error appropriately
      httpRequestErrorHandling(error, commit);
      throw error; // Rethrow the error if you want the calling function to handle it
    }

  },
  async fetchCompanyApplicants({ commit }, { businessEntityUUID }) {
    try {
      const VGS_HOST = window.zgInit.VGS_HOST
      const url = `${VGS_HOST}/api/v3/business-entities/${businessEntityUUID}/contacts?contact_type=company_applicant`;

      const response = await httpclient.get(url);
      const results = await response.data;
      commit('setCompanyApplicants', results);
    } catch (error) {
      httpRequestErrorHandling(error, commit);
    }
  },
  async fetchBusinessEntity({ commit }, { uuid }) {
    if (!validateParams.UUID(uuid)) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      // Read the business entity, store response to store
      const VGS_HOST = window.zgInit.VGS_HOST
      const url = `${VGS_HOST}/api/v3/business-entities/${uuid}`;

      const response = await httpclient.get(url)
        .then(commitResponseToStore(commit, 'setBusinessEntity'))
        .catch((e) => {
          if (e?.response?.status === 404) {
            httpRequestErrorHandling(e, commit, messages.fetchBusinessEntity)
          } else {
            httpRequestErrorHandling(e, commit)
          }
        });

      if (!response) return;

      const businessEntity = response.data;
      // Gather auxerilly data calls as promises and call all below in parallel
      const referenceDataPromises = [];

      referenceDataPromises.push(
        httpclient.get(`/api/v3/jurisdictions/business-entity/${uuid}`)
          .then(commitResponseToStore(commit, 'setJurisdictions'))
          .then((response) => {
            const { data: jurisdictions } = response;
            // Create a promise array of event configs, one for each jurisdiction
            const eventConfigPromises = jurisdictions.map((jurisdiction) => (
              httpclient.get(`/api/v2/compliance/reports/config/${businessEntity.business_entity_type}/${jurisdiction.jurisdiction_code}`,
                { params: { domestic: jurisdiction.domestic_flag ? 'true' : 'false' } })
                .then(commitResponseToStore(commit, 'setEventConfig', ({ data: eventConfig }) => ({
                  jurisdictionCode: jurisdiction.jurisdiction_code,
                  data: eventConfig.event_configs
                })))
            ));
            return Promise.all(eventConfigPromises);
          })
      );

      referenceDataPromises.push(

        httpclient.get(`/api/order-tasks/business-entity/${uuid}`)
          .then(commitResponseToStore(commit, 'setBusinessEntityTasks')),

        httpclient.get(`/api/v3/business-entities/${uuid}/addresses`)
          .then(commitResponseToStore(commit, 'setBusinessEntityAddresses')),

        httpclient.get(`/api/business-entities/${uuid}/contacts`)
          .then(commitResponseToStore(commit, 'setBusinessEntityContacts')),

        httpclient.get(`/api/v3/business-entity/${uuid}/fkas`)
          .then(commitResponseToStore(commit, 'setBusinessEntityFKAs'))
      );

      referenceDataPromises.push(
        httpclient.get('/api/v2/contact-types')
          .then(commitResponseToStore(commit, 'setContactTypes'))
      );

      await Promise.all(referenceDataPromises);
      localStorage.setItem('businessEntityName', businessEntity.business_entity_name)

      return businessEntity;
    }
  },
  async fetchDirectors({ commit }, { businessEntityUUID }) {
    try {
      const VGS_HOST = window.zgInit.VGS_HOST
      const url = `${VGS_HOST}/api/v3/business-entities/${businessEntityUUID}/contacts?contact_type=director`;

      const response = await httpclient.get(url);
      const results = await response.data;
      commit('setDirectors', results);
    } catch (error) {
      httpRequestErrorHandling(error, commit);
    }
  },
  async fetchSopContact({ commit }, { businessEntityUUID }) {
    try {
      const VGS_HOST = window.zgInit.VGS_HOST
      const url = `${VGS_HOST}/api/v3/business-entities/${businessEntityUUID}/contacts?contact_type=sop_contact`;

      const response = await httpclient.get(url);
      const results = await response.data;
      commit('setSopContact', results);
    } catch (error) {
      httpRequestErrorHandling(error, commit);
    }
  },
  async fetchMembers({ commit }, { businessEntityUUID }) {
    try {
      const VGS_HOST = window.zgInit.VGS_HOST
      const url = `${VGS_HOST}/api/v3/business-entities/${businessEntityUUID}/contacts?contact_type=member`;

      const response = await httpclient.get(url);
      const results = await response.data;
      commit('setMembers', results);
    } catch (error) {
      httpRequestErrorHandling(error, commit);
    }
  },
  async fetchManagers({ commit }, { businessEntityUUID }) {
    try {
      const VGS_HOST = window.zgInit.VGS_HOST
      const url = `${VGS_HOST}/api/v3/business-entities/${businessEntityUUID}/contacts?contact_type=manager`;

      const response = await httpclient.get(url);
      const results = await response.data;
      commit('setManagers', results);
    } catch (error) {
      httpRequestErrorHandling(error, commit);
    }
  },
  async fetchOfficers({ commit }, { businessEntityUUID }) {
    try {
      const VGS_HOST = window.zgInit.VGS_HOST
      const url = `${VGS_HOST}/api/v3/business-entities/${businessEntityUUID}/contacts?contact_type=officer`;

      const response = await httpclient.get(url);
      const results = await response.data;
      commit('setOfficers', results);
    } catch (error) {
      httpRequestErrorHandling(error, commit);
    }
  },
  async fetchPrimaryContact({ commit }, { businessEntityUUID }) {
    try {
      const VGS_HOST = window.zgInit.VGS_HOST
      const url = `${VGS_HOST}/api/v3/business-entities/${businessEntityUUID}/contacts?contact_type=primary`;

      const response = await httpclient.get(url);
      const results = await response.data;
      commit('setPrimaryContact', results);
    } catch (error) {
      httpRequestErrorHandling(error, commit);
    }
  },
  async fetchResponsibleParty({ commit }, { businessEntityUUID }) {
    try {
      const VGS_HOST = window.zgInit.VGS_HOST
      const url = `${VGS_HOST}/api/v3/business-entities/${businessEntityUUID}/contacts?contact_type=responsible_party`;

      const response = await httpclient.get(url);
      const results = await response.data;
      const contact = results?.[0]?.contact;
      commit('setResponsibleParty', contact);
    } catch (error) {
      httpRequestErrorHandling(error, commit);
    }
  },
  updateResponsibleParty({ commit }, { contactUuid, taxIdentifier }) {
    const VGS_HOST = window.zgInit.VGS_HOST;
    const url = `${VGS_HOST}/api/v2/contacts/${contactUuid}`;

    return httpclient.patch(url, { tax_identifier: taxIdentifier })
      .then(() => {
        commit('setAlertMessage', messages.updateResponsibleParty.success, { root: true });
      })
      .catch((e) => { httpRequestErrorHandling(e, commit, messages.updateResponsibleParty) });
  },
  async fetchBeneficialOwners({ commit }, { businessEntityUUID }) {
    try {
      const VGS_HOST = window.zgInit.VGS_HOST
      const url = `${VGS_HOST}/api/v3/business-entities/${businessEntityUUID}/contacts?contact_type=beneficial_owner`;

      const response = await httpclient.get(url);
      const results = await response.data;
      commit('setBeneficialOwners', results);
    } catch (error) {
      httpRequestErrorHandling(error, commit);
    }
  },
  async fetchRegisteredAgents({ commit }, { businessEntityUUID }) {
    try {
      const VGS_HOST = window.zgInit.VGS_HOST
      const url = `${VGS_HOST}/api/v1/business-entities/${businessEntityUUID}/registered-agents`;

      const response = await httpclient.get(url);
      const results = await response.data.results;
      commit('setRegisteredAgentContacts', results);
    } catch (error) {
      httpRequestErrorHandling(error, commit);
    }
  },
  async searchByName({ commit }, { name }) {
    try {
      const response = await httpclient.get('/api/v3/business-entities', { params: { business_entity_name: name } });
      const results = response.data.results;

      commit('setBusinessEntities', results);
    } catch (e) {
      httpRequestErrorHandling(e, commit, messages.searchEntitiesByName);
    }
  },
  async searchByFilingNumber({ commit }, { jurisdictionFilingNumber }) {
    try {
      const response = await httpclient.get('/api/v3/business-entities', { params: { jurisdiction_filing_number: jurisdictionFilingNumber } });
      const results = response.data.results;

      commit('setBusinessEntities', results);
    } catch (e) {
      httpRequestErrorHandling(e, commit, messages.searchEntitiesByName);
    }
  },
  fetchEventConfigs({ commit }) {
    const listOfPromises = [];
    for (let i = 0; i < state.jurisdictions.length; i++) {
      listOfPromises.push(
        httpclient.get(`/api/v2/compliance/reports/config/${state.businessEntity.business_entity_type}/${state.jurisdictions[i].jurisdiction_code}`)
          .then((response) => {
            commit('setEventConfig', { jur: state.jurisdictions[i].jurisdiction_code, data: response.data.event_configs });
          })
      );
    }
    return Promise.all(listOfPromises)
  },
  fetchEntityFilingChanges({ commit }, { uuid }) {
    if (!validateParams.UUID(uuid)) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.get(`/api/v2/compliance/entity-filing-changes?business_entity_uuid=${uuid}&page_size=100`)
        .then((response) => {
          commit('setEntityFilingChanges', response.data.results);
        })
        .catch((e) => { httpRequestErrorHandling(e, commit) });
    }
  },
  createBusinessEntity({ commit }, {
    accountUuid, entityName, entityType, domesticJurisdiction
  }) {
    if (!(validateParams.UUID(accountUuid) && entityName && entityType && domesticJurisdiction)) {
      return httpRequestErrorHandling(new Error(messages.validationParameters), commit, messages.validationParameters)
    } else {
      const data = {
        account_uuid: accountUuid,
        business_entity_name: entityName,
        business_entity_type: entityType,
        jurisdiction_code: domesticJurisdiction
      };
      return httpclient.post('/api/v3/business-entities', data)
        .then((response) => {
          commit('setAlertMessage', messages.updateEntity.success, { root: true });
          return response;
        })
        .catch((e) => { httpRequestErrorHandling(e, commit, messages.updateEntity) });
    }
  },
  deleteContacts: async ({ commit }, contacts) => {
    return await Promise.all(contacts.map((contactUuid) =>
      httpclient.delete(`/api/v2/contacts/${contactUuid}`)
    ))
      .catch((e) => {
        httpRequestErrorHandling(e, commit, messages.updateEntity)
        return e.response
      })
  },
  updateBusinessEntity({ state, commit }) {
    if (!(state.businessEntity && validateParams.UUID(state.businessEntity.business_entity_uuid))) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.put(`/api/v3/business-entities/${state.businessEntity.business_entity_uuid}`, state.businessEntity)
        .then(() => {
          commit('setAlertMessage', messages.updateEntity.success, { root: true });
        })
        .catch((e) => { httpRequestErrorHandling(e, commit, messages.updateEntity) });
    }
  },
  updateRegisteredAgentContact({ state, commit }, { jurisdictionUuid, effectiveDatetime, jurisdictionCode }) {
    const uuid = state.businessEntity.business_entity_uuid;
    const data = {
      effective_datetime: effectiveDatetime
    };
    return httpclient.post(`/api/v1/jurisdictions/${jurisdictionUuid}/registered-agent/migrate-to-zenbusiness`, data)
      .then(response => {
        httpclient.get(`/api/v1/business-entities/${uuid}/registered-agents`)
          .then(commitResponseToStore(commit, 'setRegisteredAgentContacts'))
        commit('setAlertMessage', messages.registeredAgentUpdateSuccessByJurisdiction(jurisdictionCode), { root: true })
        return response
      })
      .catch((e) => {
        httpRequestErrorHandling(e, commit, messages.updateEntity)
        return e.response
      })
  },
  deleteBusinessEntity({ commit }, { businessEntityUuid }) {
    if (!validateParams.UUID(businessEntityUuid)) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.delete(`/api/v3/business-entities/${businessEntityUuid}`)
        .then(() => {
          commit('setAlertMessage', messages.updateEntity.success, { root: true });
        })
        .catch((error) => {
          if (error.response?.status === 400) {
            const errorMessage = error.response?.data?.detail?.[0]?.error;
            httpRequestErrorHandling(
              error,
              commit,
              {
                error: {
                  ...messages.updateEntity.error,
                  secondary: errorMessage ? errorMessage.replaceAll('_', ' ') : messages.updateEntity.error.secondary
                }
              }
            );
          } else {
            httpRequestErrorHandling(error, commit, messages.updateEntity);
          }
        });
    }
  },
  createJurisdictions({ state, commit }) {
    const listOfPromises = []
    for (let i = 0; i < state.newJurisdictions.length; i++) {
      const newJurisdiction = state.newJurisdictions[i];
      const data = {
        business_entity_uuid: state.businessEntity.business_entity_uuid,
        jurisdiction_code: newJurisdiction.jurisdiction_code,
        jurisdiction_filing_date: newJurisdiction.jurisdiction_filing_date,
        jurisdiction_filing_number: newJurisdiction.jurisdiction_filing_number
      }
      listOfPromises.push(
        httpclient.post('/api/v3/jurisdictions', data)
          .then(() => {
            commit('setAlertMessage', messages.updateEntity.success, { root: true });
          })
          .catch((e) => { httpRequestErrorHandling(e, commit, messages.updateEntity) })
      );
    }
    return Promise.all(listOfPromises);
  },
  updateJurisdiction({ state, commit }) {
    return httpclient.put(`/api/v3/jurisdictions/${state.jurisdictions[0].jurisdiction_uuid}`, state.jurisdictions[0])
      .then(() => {
        commit('setAlertMessage', messages.updateEntity.success, { root: true });
      })
      .catch((e) => { httpRequestErrorHandling(e, commit, messages.updateEntity) });
  },
  updateJurisdictions({ state, commit }) {
    const listOfPromises = []
    for (let i = 0; i < state.jurisdictions.length; i++) {
      const jurisdictionData = state.jurisdictions[i];

      if (typeof (jurisdictionData.jurisdiction_filing_date) === 'object' && jurisdictionData.jurisdiction_filing_date !== null) {
        jurisdictionData.jurisdiction_filing_date = jurisdictionData.jurisdiction_filing_date.toISOString().split("T")[0]
      }

      if (jurisdictionData.jurisdiction_uuid) {
        listOfPromises.push(
          httpclient.put(`/api/v3/jurisdictions/${jurisdictionData.jurisdiction_uuid}`, jurisdictionData)
            .then(() => {
              commit('setAlertMessage', messages.updateEntity.success, { root: true });
            })
            .catch((e) => { httpRequestErrorHandling(e, commit, messages.updateEntity) })
        );
      } else {
        jurisdictionData.business_entity_uuid = state.businessEntity.business_entity_uuid;
        listOfPromises.push(
          httpclient.post('/api/v3/jurisdictions', jurisdictionData)
            .then(() => {
              commit('setAlertMessage', messages.updateEntity.success, { root: true });
            })
            .catch((e) => { httpRequestErrorHandling(e, commit, messages.updateEntity) })
        );
      }
    }
    return Promise.all(listOfPromises);
  },
  removeJurisdictions({ commit }, { jurisdictionUuids }) {
    const listOfPromises = [];
    for (let i = 0; i < jurisdictionUuids.length; i++) {
      listOfPromises.push(
        httpclient.delete(`/api/v3/jurisdictions/${jurisdictionUuids[i]}`)
          .then(() => {
            commit('setAlertMessage', messages.updateEntity.success, { root: true });
          })
          .catch((e) => { httpRequestErrorHandling(e, commit, messages.updateEntity) })
      );
    }
    return Promise.all(listOfPromises);
  },
  fetchEntitiesByAccount({ commit }, { accountUuid }) {
    if (!validateParams.UUID(accountUuid)) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      commit('setBusinessEntities', []);
      return httpclient.get(`/api/v3/business-entities/account/${accountUuid}?include_inactive=true`)
        .then((response) => {
          commit('setBusinessEntities', response.data);
        })
        .catch((e) => { httpRequestErrorHandling(e, commit) });
    }
  },
  fetchComplianceEventsByEntity({ commit }, { businessEntityUuid }) {
    if (!validateParams.UUID(businessEntityUuid)) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.get(`/api/v2/business-entity/${businessEntityUuid}/compliance-events`)
        .then((response) => {
          commit('setComplianceEvents', response.data.results);
        })
        .catch((e) => { httpRequestErrorHandling(e, commit) });
    }
  },
  fetchDocuments({ commit }, args) {
    const { uuid, page, pageSize, sortBy, sortDirection } = args;
    const urlSearchParams = new URLSearchParams({
      ...page && { page },
      ...pageSize && { page_size: pageSize },
      ...sortBy && { order_by_field: sortBy },
      ...sortDirection && { asc: sortDirection === 'asc' ? 'True' : 'False' },
      include_inactive: 'True'
    })
    if (!validateParams.UUID(uuid)) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.get(`/api/v2/business-entity/${uuid}/documents?${urlSearchParams.toString()}`)
        .then((response) => {
          commit('setDocuments', response.data);
        })
        .catch((e) => { httpRequestErrorHandling(e, commit) });
    }
  },
  removeDocument({ commit }, { uuid }) {
    if (!validateParams.UUID(uuid)) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.delete(`/api/documents/${uuid}`)
        .then(() => {
          // eslint-disable-next-line no-empty
        })
        .catch((e) => { httpRequestErrorHandling(e, commit) });
    }
  },
  fetchDocumentRead({ commit }, { uuid, documentUuid }) {
    if (!(validateParams.UUID(uuid) && validateParams.UUID(documentUuid))) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.get(`/api/v3/business-entities/${uuid}/documents/${documentUuid}/read`)
        .catch((e) => { httpRequestErrorHandling(e, commit) });
    }
  },
  fetchDocumentOwnerRead({ commit }, { uuid, documentUuid }) {
    if (!(validateParams.UUID(uuid) && validateParams.UUID(documentUuid))) {
      httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.get(`/api/v2/business-entity/${uuid}/documents/${documentUuid}/owner-read`)
        .catch((e) => { httpRequestErrorHandling(e, commit) });
    }
  },
  getAndOpenSecuredDocumentUrl({ commit }, { uuid, documentUuid }) {
    if (!(validateParams.UUID(uuid) && validateParams.UUID(documentUuid))) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.get(`/api/v2/business-entities/${uuid}/documents/${documentUuid}/view`)
        .then((response) => {
          commit('setDocumentViewUrl', response.data.url);
        })
        .catch((e) => { httpRequestErrorHandling(e, commit) });
    }
  },
  fetchAuditRecords({ commit }, { uuid }) {
    if (!validateParams.UUID(uuid)) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.get(`/api/v2/business-entity/${uuid}/audit-records`)
        .then((response) => {
          commit('setAuditRecords', response.data.business_entity_audit_records);
        })
        .catch((e) => { httpRequestErrorHandling(e, commit) });
    }
  },
  updateFKAs({ commit }, { uuid }) {
    if (!validateParams.UUID(uuid)) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.put(`/api/v3/business-entity/${uuid}/fkas`, state.fkas)
        .catch((e) => { httpRequestErrorHandling(e, commit) });
    }
  },
  fetchRaTrackingRecords({ commit }, { businessEntityUUID, jurisdiction }) {
    // As of writing this comment, this endpoiht has existed in ZG for ~1yr with no actual use
    //  - the RA Tracking records were deemed too incorrect to be safely shown and relied on
    //  Instead this endpoint just threw the occasional error and generated an #app-prod question
    //  As of this comment, all calls to this method have been removed, and it's been left
    //    in the event we decide to reintroduce the tracking table modal in ZG
    //  2022-08-29
    const uuid = businessEntityUUID
    let endpoint = `/api/registered-agents/v2/tracking/${uuid}`
    if (jurisdiction) {
      endpoint += `/${jurisdiction}`
    }
    endpoint += '?page_size=100'
    if (!validateParams.UUID(uuid)) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.get(endpoint)
        .then((response) => {
          commit('setRaTrackingRecords', response.data.results);
        })
        .catch((e) => { httpRequestErrorHandling(e, commit) });
    }
  },
  // this has to be async so we can get the user uuid when we need it
  async addDocument({ commit }, {
    accountUuid,
    businessEntityUuid,
    fileName,
    fileLink,
    fileUuid,
    fileCategory,
    fileSubcategory,
    createComplianceNotificationCase,
    jurisdictionCode
  }) {
    if (!(validateParams.UUID(accountUuid) && validateParams.UUID(businessEntityUuid))) {
      return httpRequestErrorHandling(new Error(messages.validationParameters), commit, messages.validationParameters)
    } else {
      const docData = {
        external_id: fileUuid,
        account_uuid: accountUuid,
        business_entity_uuid: businessEntityUuid,
        name: fileName,
        category: fileCategory,
        subcategory: fileSubcategory,
        document_url: fileLink,
        meta_data: fileLink,
        source: 'zg_upload',
        uploaded_by: authService.getLoggedInEmail(),
        create_compliance_notification_case: createComplianceNotificationCase,
        jurisdiction_code: jurisdictionCode
      };

      return httpclient.post('/api/documents', docData)
        .then(() => {
          const successMessage = { primary: `Success! Uploaded ${fileName} to ${fileLink}`, type: 'alert alert-success' };
          commit('setAlertMessage', successMessage, { root: true });
        }).then(async () => {
          if (pushNotificationForDocUpload.enabled) {
            const businessEntityName = localStorage.getItem('businessEntityName');
            const userUuidInfo = await httpclient.get(`/api/v2/accounts/${accountUuid}/users`);
            const userUuid = (userUuidInfo.data[0].user_uuid);
            const pushEndpoint = `communications/push/v2/new_document`;
            const payload = {
              "account_uuid": accountUuid,
              "user_uuid": userUuid,
              "business_entity_uuid": businessEntityUuid,
              "business_entity_name": businessEntityName,
            };
            httpclient.post(pushEndpoint, payload)
          }
        })
        .catch((e) => { httpRequestErrorHandling(e, commit, messages.fileUploadStatus) });
    }
  },
  updateDocument({ commit }, { documentUuid, documentData }) {
    if (!validateParams.UUID(documentUuid)) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.put(`/api/documents/${documentUuid}`, documentData)
        .then(() => {
          const successMessage = { primary: 'Success! Updated document.', type: 'alert alert-success' };
          commit('setAlertMessage', successMessage, { root: true });
        })
        .catch((e) => { httpRequestErrorHandling(e, commit, messages.fileUpdate) });
    }
  },
  deleteDocument({ commit }, { documentUuid }) {
    if (!validateParams.UUID(documentUuid)) {
      httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.delete(`/api/documents/${documentUuid}`)
        .then(() => {
          const successMessage = { primary: 'Success! Deleted document.', type: 'alert alert-success' };
          commit('setAlertMessage', successMessage, { root: true });
        })
        .catch((e) => { httpRequestErrorHandling(e, commit, messages.fileDelete) });
    }
  },
  // zenapi needs to support updateing website_url before updating this to v2
  fetchBusinessEntityCreditBalances({ commit }, { businessEntityUuid }) {
    if (!validateParams.UUID(businessEntityUuid)) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.get(`/api/credit-balances/business-entity/${businessEntityUuid}`)
        .then((response) => {
          commit('setCreditBalances', response.data);
        })
        .catch((e) => { httpRequestErrorHandling(e, commit) });
    }
  },
  updateCreditBalance({ commit }, { uuid, extraCredits }) {
    if (!validateParams.UUID(uuid)) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      const creditData = {
        extra_credits: extraCredits
      };
      return httpclient.put(`/api/credit-balances/${uuid}`, creditData)
        .then(() => {
          // eslint-disable-next-line no-empty
        })
        .catch((e) => { httpRequestErrorHandling(e, commit) });
    }
  },
  fetchDocumentsByAccount({ commit }, uuid) {
    if (!validateParams.UUID(uuid)) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.get(`/api/v2/documents?account_uuid=${uuid}&active_flag=true&page_size=100`)
        .then(commitResponseToStore(
          commit,
          'setDocuments',
          (response) => response.data.results
        ))
        .catch((e) => { httpRequestErrorHandling(e, commit) });
    }
  },
  setHasMoreThanOneShare({ commit }, hasMoreThanOneShare) {
    commit('setHasMoreThanOneShare', hasMoreThanOneShare)
  },
  setAuthorizedShares({ commit }, shares) {
    commit("setAuthorizedShares", shares);
  },
};

export default {};
