import httpclient from '../../httpclient';
import messages from '../../../assets/messages';
import router from '../../../router';
import { commitResponseToStore, httpRequestErrorHandling, validateParams } from '../utils';
import { trackButtonPressed } from '@/client/avoRudderstack';

export default {
  fetchAccounts({ commit }) {
    return httpclient.get('/api/v2/accounts', { params: { page_size: 100 } })
      .then((response) => {
        commit('setAccounts', response.data.results);
      })
      .catch((e) => {httpRequestErrorHandling(e, commit)});
  },
  fetchAccount({ dispatch, commit }, { accountUuid }) {
    if(!validateParams.UUID(accountUuid)) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      dispatch('fetchAuth0UserInfo', accountUuid);
      dispatch('fetchComplianceEventsByAccount', accountUuid);
      return httpclient.get(`/api/v2/accounts/${accountUuid}`)
        .then((response) => {
          commit('setAccount', response.data);
        })
        .catch((e) => {httpRequestErrorHandling(e, commit)});
    }
  },
  obfuscateAccount({ dispatch, commit }, { accountUuid }) {
    if(!validateParams.UUID(accountUuid)) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      const url = `api/v2/accounts/${accountUuid}/obfuscate-account-data`
      return httpclient.put(url)
        .then(() => {
          // After obfuscation immediately refetch the account set state to now-obfuscated values
          dispatch('fetchAccount', {accountUuid})
        })
        .catch(() => {
          commit('setAlertMessage', messages.accountObfuscateFailure.error, {root: true})
        });
    }
  },
  findByEmail({ commit }, { email }) {
    return httpclient.get('/api/v2/accounts', { params: { account_email: email } })
      .then((response) => {
        const { results } = response.data;
        if (results.length > 0 && validateParams.UUID(results[0].account_uuid)) {
          router.push({ name: 'Account', params: { accountUuid: results[0].account_uuid } });
          commit('setAlert', false, { root: true });
        } else {
          commit('setAlertMessage', messages.findByEmail.error, { root: true });
        }
      })
      .catch(error => {
        // Response of 422 indicates no entity was found; not an error response
        if (error.response.status !== 422) {
          console.log(error);
        }
        return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
      })
  },
  async fetchUsers({ commit }, { accountUuid }) {
    if (!validateParams.UUID(accountUuid)) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      let users = await httpclient.get(`/api/v2/accounts/${accountUuid}/users`)
        .then((response) => {
          return response.data;
        })
        .catch((e) => {
          httpRequestErrorHandling(e, commit);
        });

      if (!users) return;

      return Promise.all(users.map((user) => {
        return Promise.all([
          httpclient.get(`/customers/v1/auth/${user.user_uuid}`),
          httpclient.get(`/customers/auth/v2/${user.user_uuid}/mfa/sms`)
        ])
          .then((responses) => {
            let [mfaResponse, mfaDetailsResponse] = responses;
            user.mfaStatus = mfaResponse.data.mfaEnabled;
            user.auth0Email = mfaResponse.data.email;
            user.mfaDetails = mfaDetailsResponse.data;
            return user;
          })
          .catch((e) => {
            httpRequestErrorHandling(e, commit);
            return user;
          })
      }))
        .then((augmentedUsers) => {
          commit('setUsers', augmentedUsers);
        });
    }
  },
  fetchCharges({ commit }, { accountUuid }) {
    if(!validateParams.UUID(accountUuid)) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.get(`/api/v2/accounts/${accountUuid}/charges`)
        .then((response) => {
          commit('setCharges', response.data);
        })
        .catch((e) => {httpRequestErrorHandling(e, commit)});
    }
  },
  createAccount({ state, commit }) {
    const accountCopy = { ...state.account };
    if (accountCopy.account_type === 'reseller') {
      delete accountCopy.card;
    } else if (accountCopy.account_type === null) {
      delete accountCopy.account_type;
    }
    accountCopy.account_email = accountCopy.email;
    accountCopy.account_phone = accountCopy.phone;
    return httpclient.post('/api/v2/accounts', accountCopy)
      .then((response) => {
        commit('setAlertMessage', messages.createAccount.success, { root: true });
        router.push({ name: 'Account', params: { accountUuid: response.data.account_uuid } });
      })
      .catch((e) => {httpRequestErrorHandling(e, commit, messages.createAccount)});
  },
  updateAccount({ state, commit }) {
    if(!(state.account && validateParams.UUID(state.account.account_uuid))) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.put(`/api/v2/accounts/${state.account.account_uuid}`, state.account)
        .then(() => {
          commit('setAlertMessage', messages.updateAccount.success, { root: true });
        })
        .catch((e) => {
          let message = { ...messages.updateAccount}
          if (e.response.status === 422){
            message.error.secondary = e.response.data.error
          }
          httpRequestErrorHandling(e, commit, message)});
    }
  },
  sendPasswordResetEmail({ commit }, { user }) {
    if(!(user && validateParams.UUID(user.user_uuid))) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.post(`/api/users/${user.user_uuid}/send-password-reset-email`)
        .then(() => {
          const successMessage = { ...messages.sendPasswordResetEmail.success };
          successMessage.primary += user.user_email;
          commit('setAlertMessage', successMessage, { root: true });
        })
        .catch((error)=>{
          const errorMessage = { ...messages.sendPasswordResetEmail.error };
          errorMessage.primary += user.user_email;
          return httpRequestErrorHandling(error, commit, errorMessage)
        })
    }
  },
  fetchAuth0UserInfo({ commit }, uuid) {
    if(!validateParams.UUID(uuid)) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.get(`/api/v2/accounts/${uuid}/auth0-user-info`)
        .then(commitResponseToStore(commit, 'setAuth0User'))
        .catch((e) => {httpRequestErrorHandling(e, commit)});
    }
  },
  fetchAuth0UserLogs({ commit }, {uuid, page}) {
    if(!validateParams.UUID(uuid)) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.get(`/customers/v1/auth/${uuid}/logs?page=${page}`)
        .then(commitResponseToStore(commit, 'setAuth0UserLogs'))
        .catch((e) => {httpRequestErrorHandling(e, commit)});
    }
  },
  fetchComplianceEventsByAccount({ commit }, uuid) {
    if(!validateParams.UUID(uuid)) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      return httpclient.get(`/api/v2/compliance/compliance-events?account_uuid=${uuid}&page_size=100`)
        .then((response) => {
          const { results } = response.data;
          commit('businessEntities/setComplianceEvents', results, { root: true });
        })
        .catch((e) => {httpRequestErrorHandling(e, commit)});
    }
  },
  resetUserMfa({ commit }, { userUuid, userTrackingInfo }) {
    if (!validateParams.UUID(userUuid)) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      // Record user tracking event
      trackButtonPressed(userTrackingInfo);
      return httpclient.delete(`/customers/v1/auth/${userUuid}/mfa/sms`)
        .then(() => {
          commit('setAlertMessage', messages.userMfaReset.success, { root: true });
        })
        .catch((e) => {httpRequestErrorHandling(e, commit)});
    }
  },
  toggleUserMfa({ commit }, { userUuid, enabled, userTrackingInfo }) {
    if (!validateParams.UUID(userUuid)) {
      return httpRequestErrorHandling(new Error(messages.validationUUID.error.primary), commit, messages.validationUUID)
    } else {
      // Record user tracking event
      trackButtonPressed(userTrackingInfo);
      const requestData = {mfaEnabled: enabled}
      return httpclient.patch(`/customers/v1/auth/${userUuid}`, requestData)
        .then(() => {
          commit('setAlertMessage', enabled ? messages.userMfaEnabled.success : messages.userMfaDisabled.success,
            { root: true });
        })
        .catch((e) => {httpRequestErrorHandling(e, commit)});
    }
  }
};
