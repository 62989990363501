import { mapState, mapActions } from 'vuex';
import { getNAICSLabel, getNAICSCodeOptions, getNAICSShortCodeOptions } from '../assets/naicsCodes';
import { allowAnnualReportConfEditing, extractRefunds } from './utils/global-utils';
import { AUTOMATION_STATUSES } from '@/assets/constants/automations'
import manifest from '../manifest';
import moment from 'moment';
import authService from '../auth/AuthService';

export const yesNoMixin = {
  filters: {
    yesno(value) {
      return value ? 'Yes' : 'No';
    }
  }
};

export const readableRecurringPeriodMixin = {
  filters: {
    readableRecurringPeriod(value) {
      // This could come in as either a number or string, so let's compare as strings
      const period = value.toString()
      if (period === '1') {
        return 'Annual';
      }
      else if(period === '12') {
        return 'Monthly';
      }
      return period;
    }
  }
}

export const managementStructureMixin = {
  filters: {
    managementStructurePresenter(value) {
      switch (value) {
        case 'all_owners':
          return 'All the owners (Member managed)';
        case 'some_owners':
          return 'Only some of the owners (Manager managed)';
        case 'some_managers':
          return 'Some or all of the owners and other managers (Manager managed)';
        case 'all_managers':
          return 'None of the owners, only other managers (Manager managed)';
        default:
          return value;
      }
    }
  }
};

export const nullMixin = {
  filters: {
    nullval(value) {
      return value || 'Not Set';
    }
  }
};

export const textMixin = {
  filters: {
    zbTruncate(value, limit) {
      if (value.length > limit) {
        value = value.substring(0, limit - 3) + '...';
      }

      return value;
    },
    valueOrNone(value) {
      return value?value:'None';
    }
  },
  truncate(value, limit) {
    if (value.length > limit) {
      value = value.substring(0, limit);
    }

    return value;
  }
};

export const hasPermissionMixin = {
  methods: {
    hasPermission(permission) {
      try {
        const accessToken = authService.getAccessToken() || '';
        if (!accessToken) return false;
        const { permissions } = JSON.parse(atob(accessToken.split('.')[1]))
        if (permissions !== null && permissions !== undefined) {
          return permissions.includes(permission);
        }
      } catch (e) {
        // eslint-disable-next-line no-empty
      }
      return false;
    }
  }
};

export const manifestMixin = {
  computed: {
    environment() {
      return manifest.environment;
    }
  }
};

export const clipboardCopyMixin = {
  data() {
    return {
      showCopy: false,
      showCopied: false
    };
  },
  methods: {
    onMouseover() {
      this.showCopy = true;
    },
    onMouseleave() {
      this.showCopy = false;
      this.showCopied = false;
    },
    changeShowCopied(value) {
      this.showCopied = value;
    }
  }
};

export const orderCreateProductMixin = {
  computed: {
    nyCounty: {
      get () {
        return this.productData.order_data.ny_publication_county || ''
      },
      set (value) {
        this.productData.order_data.ny_publication_county = value
        this.productData.order_item.fees = this.pricing.fees[value][this.filingType] + this.stateFee
      }
    },
    waivePrice: {
      get() {
        return this.waiveZen;
      },
      set(value) {
        this.waiveZen = value;
        this.productData.order_item.price = (value === true) ? 0 : this.pricing.price;
      }
    },
    hasFeeException: {
      get() {
        return this.pricing.has_exception;
      }
    },
    fees: {
      get() {
        return this.productData.order_item.fees;
      },
      set(value) {
        this.productData.order_item.fees = ~~value;
      }
    },
    price: {
      get() {
        return this.productData.order_item.price;
      },
      set(value) {
        this.productData.order_item.price = ~~value;
        if (~~value === 0) {
          this.applyCoupon = false;
          this.setCoupon();
        }
      }
    },
    annual_price: {
      get() {
        if (this.pricing.prices !== undefined && this.pricing.prices['1'] !== undefined) {
          return this.pricing.prices['1'];
        }
        return this.pricing.price;
      }
    },
    monthly_price: {
      get() {
        if (this.pricing.prices !== undefined && this.pricing.prices['12'] !== undefined) {
          return this.pricing.prices['12'];
        }
        return this.pricing.price;
      }
    },
    transOrRecurring: {
      get() {
        if (this.pricing === undefined || this.pricing.period === undefined) {
          return 'Transactional';
        }
        return 'Recurring';
      }
    },
    period: {
      get() {
        return this.productData.order_item.period;
      },
      set(value) {
        this.productData.order_item.period = ~~value;
        // if pricing was provided as an initialization parameter then this is not a plan
        if (this.pricing) {
          const { prices } = this.pricing;
          if (value in prices) {
            this.price = prices[value];
          } else {
            // Hacky mechanism to add a pricing plan. The value field of an option must be set to the desired price. The
            // period defaults to 1. The base issue here is that by re-activating the $99 price in the DB, the RA price
            // for zenbusiness.com and zenreg is affected.
            // todo: Need have pricing specifically for ZenGarden, price_group not the likely solution.
            this.price = value;
            this.productData.order_item.period = 1;
          }
        } else {
          // otherwise, its a plan which gets pricing from a different place
          if (this.plans[this.planService].llc.prices) {
            this.price = this.plans[this.planService].llc.prices[value];
          } else {
            this.price = this.plans[this.planService].llc.price;
          }
        }
      }
    },
    planService: {
      get() {
        return this.productData.order_data.service;
      },
      set(value) {
        // note that we are always going to use the llc fee here since they are the same and
        // there is no llc or corp formation in this order.  Looking up the type off the business
        // entity wouldn't be too hard.
        if (value !== this.productData.order_item.service && this.productData.order_item.service !== null) {
          if (this.plans[value].llc.prices) {
            this.price = this.plans[value].llc.prices[this.period];
          } else {
            this.price = this.plans[value].llc.price;
          }
        }
        this.productData.order_data.service = value;
        this.productData.order_item.service = value;
      }
    },
    getValidationObject: function() {
      if (this.productData.order_data.service) {
        return {
          serviceName: this.productData.order_data.service,
          isValid: this.isValid
        }
      }
    },
    isValid: () => {
      return true;
    }
  },

  methods: {
    ...mapActions({
      addValidationProduct: 'orderCreate/addValidationProduct',
      removeValidationProduct: 'orderCreate/removeValidationProduct',
      updateValidationProduct: 'orderCreate/updateValidationProduct'
    }),
    emitProductData() {
      this.$emit('input', this.productData);
    },
    setCoupon() {
      if (this.applyCoupon) {
        this.productData.order_item.coupon_uuid = this.coupon.coupon_uuid;
      } else {
        delete this.productData.order_item.coupon_uuid;
      }
    },
    getTermAndPeriodAccordingIntervalLabel(interval) {
      // Logic for setting term and period.
      // According to https://github.com/zenbusiness/zenapi/blob/48af3c3c4563300f80f4ef19bee4d96428bd437b/service_products/services.py#L449
      let new_term = 12;
      let new_period = '';
      if(interval === 'annual') {
        new_period = 1;
      } else if(interval === 'monthly') {
        new_period = 12;
      } else {
        new_term = '';
        new_period = '';
      }
      return { term: new_term, period: new_period }
    },
  },

  data() {
    return {
      productData: {
        order_data: {
          created_by: null,
          entity_name: null,
          business_entity_name: null,
          standalone: true,
          service_description: null,
          is_new_business: false
        },
        order_item: {}
      },
      waiveZen: false,
      applyCoupon: false
    };
  },

  created() {
    this.$emit('input', this.productData);
    this.addValidationProduct(this.getValidationObject);
  },

  beforeDestroy() {
    this.$emit('input', null);
    this.removeValidationProduct(this.getValidationObject);
  },

  updated() {
    // Update object of validation fields
    this.updateValidationProduct(this.getValidationObject);
  },
};

export const computeFullNameOfPersonMixin = {
  methods: {
    computeFullNameOfPerson(person) {
      if (person.company_name) {
        let displayName = person.company_name;
        if (person.first_name) {
          displayName += ` (care of ${person.first_name})`;
        }
        return displayName;
      }
      return [person.first_name, person.middle_name, person.last_name, person.suffix]
        .filter((part) => (!!part))
        .map((part) => part.trim())
        .join(' ');
    }
  }
};

export const naicsCodesMixins = {
  methods: {
    getCodeLabel(code) {
      return getNAICSLabel(code);
    },
    getCodeOptions(shortCode) {
      return getNAICSCodeOptions(shortCode);
    },
    getShortCodeOptions() {
      return getNAICSShortCodeOptions();
    }
  }
};

export const domainNameMixin = {
  methods: {
    validateDomain(domain) {
      const regex = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/i;
      return regex.exec(domain);
    }
  }
};

export const tempDomainNameMixin = {
  methods: {
    validateTempDomain(domain) {
      const regex = /^temp-/;
      return regex.exec(domain);
    }
  }
};

export const emailUserNameMixin = {
  methods: {
    validateEmailAddress(emailAddress) {
      const regex = /^[a-z0-9][a-z0-9-=_]{1,64}(\.[a-z0-9-=_]+)*$/i;
      return regex.exec(emailAddress);
    }
  }
};

export const emailAddressAvailabilityMixin = {
  methods: {
    emailAddressAvailability(emailAddress, domain_name, emails) {
      if (domain_name === undefined || emailAddress === undefined || emails === undefined) {
        return false;
      }
      const email = emails.find((email) => email.domain_name === domain_name && email.email_address === emailAddress);
      if (email === undefined) {
        return true;
      } else {
        return false;
      }
    }
  }
};

export const amendmentReportMixin = {
  computed: {
    filingChange() {
      return this.entityFilingChanges.find((filingChange) => filingChange.order_task_uuid === this.orderTask.order_task_uuid);
    },
    fieldConfig() {
      const entityType = this.businessEntity.business_entity_type;
      const filingType = this.isDomestic ? 'domestic' : 'foreign';
      const service = this.orderTask.order_task_type.endsWith('_annual_report') ? 'annual_report' : 'amendment';
      if (this.reportFieldConfig[entityType] && this.reportFieldConfig[entityType][filingType]) {
        return this.reportFieldConfig[entityType][filingType][service];
      }
      return undefined;
    },
    isDomestic() {
      const domesticJur = this.jurisdictions.find((j) => j.domestic_flag);
      return domesticJur && domesticJur.jurisdiction_code === this.orderTask.order_task_jurisdiction;
    }
  },
  mounted() {
    this.$store.dispatch('businessEntities/fetchBusinessEntity', { uuid: this.orderTask.business_entity_uuid });
    this.$store.dispatch('businessEntities/fetchEntityFilingChanges', { uuid: this.orderTask.business_entity_uuid });
    this.$store.dispatch('products/fetchReportFieldConfigByJurisdiction', { jurisdictionCode: this.orderTask.order_task_jurisdiction });
  }
};

export const editAnnualReportConfMixin = {
  computed: {
    isAllowedToEditAnnualReportConf() {
      const userEmail = authService.getLoggedInEmail();
      return allowAnnualReportConfEditing(userEmail);
    }
  }
};

export const userInformationMixin = {
  methods: {
    getUserEmail() {
      const userEmail = authService.getLoggedInEmail();
      return userEmail;
    }
  }
}

export const entityFilingChangeMixin = {
  mixins: [computeFullNameOfPersonMixin],
  methods: {
    authorizedSignatoryTitle(authorizedSignatory) {
      let { title } = authorizedSignatory;
      if (!title) {
        title = authorizedSignatory.titles ? authorizedSignatory.titles.filter((t) => t !== 'authorized_signatory').map((t) => t.replace('_', ' ')).join(', ') : '';
      }
      return title;
    },
    changeSupportsField(fieldName) {
      return this.fieldConfig && Object.keys(this.fieldConfig).includes(fieldName);
    }
  }
};

export const moneyMixin = {
  methods: {
    money(amount) {
      return `$${parseFloat(amount).toFixed(2)}`;
    }
  }
};

export const automatedFilingMixin = {
  data() {
    return {
      // https://counsl.atlassian.net/wiki/spaces/TEC/pages/854261864/Filing+Automation+Statuses
      noRetryMessage: 'Retries are disabled for this status. If you need to retry this automated filing please escalate.',
      adminMessage: 'This feature is restricted to automation admins.',
      adminRetryMessage: 'Retries for this status are allowed because you are an automation admin.',
      confirmationMessage: 'If you can no longer see this image then it has expired.',
      averageCompletionTimeMessage: 'Average completion time is calculated on the last 20 automated filings.'
    };
  },

  created() {
    this.$store.dispatch('orders/fetchAutomationConfig');
  },

  computed: {
    ...mapState({
      automationConfig: (state) => state.orders.automationConfig
    }),
    allowAutomatedFiling() {
      if (this.automatedFiling === undefined) {
        return true;
      }
      const { status } = this.automatedFiling;
      return AUTOMATION_STATUSES.retryStatuses.includes(status) || Object.keys(this.automatedFiling).length === 0;
    },
    ifAutomatedFiling() {
      return this.automatedFiling === undefined ? false : (Object.keys(this.automatedFiling).length);
    },
    isAutomationAdmin() {
      return this.hasPermission('automation:admin');
    },
    isAutomationProcessor() {
      return this.hasPermission('automation:processor');
    },
    isAutomationAdminOrProcessor() {
      return this.isAutomationAdmin || this.isAutomationProcessor;
    },
    onProd() {
      return manifest.environment === 'production';
    }
  },
  methods: {
    hasAutomatedPermission(serviceProduct, jurisdiction = null) {
      const [config] = this.automationConfig.filter(
        (ac) => ac.jurisdiction === `US_${this.jurisdiction || jurisdiction}` && ac.service_product === serviceProduct
      );
      if (config !== undefined) {
        const { enabled } = config;
        return this.isAutomationAdminOrProcessor && enabled;
      }
      return false;
    }
  }
};

export const orderMixin = {
  computed: {
    hasOrder() {
      return this.order !== undefined;
    },
    hasOrderItems() {
      return this.hasOrder && this.orderItems.length;
    },
    orderItems() {
      return this.order.order_items || [];
    }
  },
  methods: {
    hasService(serviceProduct) {
      return this.hasOrderItems ? this.orderItems.find((oi) => oi.service === serviceProduct) !== undefined : false;
    }
  }
};

export const refundsMixin = {
  methods: {
    getTotalRefunds(refunds, chargeType) {
      return extractRefunds(refunds, chargeType);
    }
  }
};

function validateEmail(email) {
  const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return mailformat.test(email);
}

export const validValue = function (value) {
  return !!value;
};

export const validRequiredValue = function (value, variable) {
  return (variable in this.requiredFields) ? !!value : true;
};

export const inputValidationsMixin = {
  methods: {
    validateEmail,
    validValue,
    validRequiredValue
  }
};

export const capitalizeAndReplaceUnderscoreMixin = {
  filters: {
    capitalizeAndReplaceUnderscore(text) {
      return typeof text === 'string' ? (text.charAt(0).toUpperCase() + text.slice(1)).replace(/_/g, ' ') : text;
    },
    capitalizeAllWordsAndReplaceUnderscore(text) {
      if (typeof text === 'string') {
        const words = text.split('_');
        const capitalizedWords = words.map((element) => {
          if(element.length>0) {
            if(element.length<=2) {
              return element.toUpperCase();
            } else {
              return element.charAt(0).toUpperCase() + element.slice(1)
            }
          } else {
            return element;
          }
        });
        return capitalizedWords.join(' ');
      } else {
        return text;
      }
    }
  }
};

export const getContactTitlesMixin = {
  filters: {
    getContactTitles(value) {
      value = value.map((title) => title.charAt(0).toUpperCase() + title.slice(1).replace('_', ' '));
      return value.join(', ');
    }
  }
};

export const orderTaskTypeMixin = {
  filters: {
    orderTaskType(orderTask) {
      let taskType = orderTask.order_task_type;
      if (taskType === 'business_license_report') {
        taskType += (orderTask.order_task_data &&
          'business_data' in orderTask.order_task_data &&
          'industry_id' in orderTask.order_task_data.business_data)
          ? ' (V2)'
          : ' (V1)';
      }
      return taskType;
    }
  }
};

export const sortingDataMixin = {
  methods: {
    sorting(sortBy, reverse) {
      return (a, b) => {
        const modifier = reverse ? -1 : 1;
        let dataA;
        let dataB;
        /**
         * when it's a compisite field, you must send the fields separated by |
         * it's necessary concat the fields and sort as string
         */
        if (sortBy.includes('|')) {
          const fieldsForSorting = sortBy.split('|');
          dataA = fieldsForSorting.map((item) => a[item]).join(' ');
          dataB = fieldsForSorting.map((item) => b[item]).join(' ');

          return dataA.localeCompare(dataB) * modifier;
        }

        /**
         * when it's a child field, it's necessary navigate from root to specific field
         * you must send the hierarchy of fields separated by . (a dot)
         */
        if (sortBy.includes('.')) {
          const fieldsForSorting = sortBy.split('.');
          let objectA; let
            objectB;

          for (const field of fieldsForSorting) {
            if (typeof objectA === 'undefined') {
              objectA = a[field];
            } else if (typeof objectA === 'object' && typeof objectA[field] === 'object') {
              objectA = objectA[field];
            } else if (typeof objectA[field] === 'undefined') {
              dataA = '';
            } else {
              dataA = objectA[field];
            }

            if (typeof objectB === 'undefined') {
              objectB = b[field];
            } else if (typeof objectB === 'object' && typeof objectB[field] === 'object') {
              objectB = objectB[field];
            } else if (typeof objectB[field] === 'undefined') {
              dataB = '';
            } else {
              dataB = objectB[field];
            }
          }
        }

        /**
         * by default a direct comparison is made
         */
        if (!dataA && !dataB) {
          dataA = a[sortBy];
          dataB = b[sortBy];
        }

        if ((typeof dataA === 'string' && dataA) && (typeof dataB === 'string' && dataB)) {
          return dataA.localeCompare(dataB) * modifier;
        }
        if (dataA < dataB) return -1 * modifier;
        if (dataA > dataB) return 1 * modifier;
        return 0;
      };
    }
  }
};

export const momentMixin = {
  methods: {
    toMoment(date, format = 'MMMM DD YYYY, h:mm:ss a') {
      if (date) {
        return moment(date).format(format);
      }
      return '';
    }
  }
};

export const memberRoleMixin = {
  filters: {
    memberRole(value) {
      switch (true) {
        case value.includes('manager') && value.includes('member'):
          return 'Managing Member';
        case value.includes('manager') && !value.includes('member'):
          return 'Manager';
        case value.includes('member'):
          return 'Member';
        default:
          return 'Unknown';
      }
    }
  }
};
