<template>
  <div id="app">
    <aside v-if="isDevelopment" class="navbar-expand-lg stripe-dev text-center fw-bold MEANINGLESS_CLASS_SO_WE_CAN_REBUILD_ZG">
      {{ getEnvironment.toUpperCase() }}
    </aside>
    <nav id="zgnav" class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid">
        <a href="/" class="navbar-brand">
          <CircleLogoTeal logoSize="30" class="d-inline-block align-top" alt="Zenbusiness Logo" /> ZenGarden
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Zengarden toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0" v-if="!isUnisearch">
            <li class="nav-item" v-if="authenticated">
              <router-link class="btn btn-nav" to="/account">Accounts</router-link>
            </li>
            <li class="nav-item" v-if="authenticated">
              <router-link class="btn btn-nav" to="/order">Orders</router-link>
            </li>
            <li class="nav-item me-2" v-if="authenticated">
              <router-link class="btn btn-nav" to="/business-entity">Business Entities</router-link>
            </li>

            <li class="nav-item dropdown" v-if="hasPermission('access:developers') && authenticated">
              <button type="button" class="btn btn-nav dropdown-toggle" id="navbarDeveloperDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                Developers
              </button>
              <ul class="dropdown-menu" aria-labelledby="navbarDeveloperDropdown">
                <li>
                  <a class="dropdown-item" href="https://engineering.zenbusiness.team/catalog/default/api/zenapi/definition" target="_blank">ZenAPI Specification</a>
                </li>
                <li>
                  <router-link class="dropdown-item" to="/developers/data-alerts">Data Alerts</router-link>
                </li>
                <li>
                  <router-link class="dropdown-item" to="/developers/components">ZenGarden Components</router-link>
                </li>
                <li>
                  <router-link
                    class="dropdown-item"
                    to="/developers/retry-order-creation"
                    >Order creation error handling
                  </router-link>
                </li>
              </ul>
            </li>

            <li class="nav-item dropdown" v-if="authenticated">
              <button type="button" class="btn btn-nav dropdown-toggle" id="navbarProductsDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                Products &amp; Pricing
              </button>
              <ul class="dropdown-menu" aria-labelledby="navbarProductsDropdown">
                <li>
                  <router-link class="dropdown-item" to="/ecomm/price-lists" target="_blank" v-if="hasPermission('ecat:admin')" >Price List Manager</router-link>
                </li>
                <li>
                  <router-link class="dropdown-item" to="/products/create-new-affiliate">Manage Affiliates</router-link>
                </li>
                <li>
                  <router-link class="dropdown-item" to="/products/affiliate-pricing">Affiliate Pricing</router-link>
                </li>
                <li>
                  <router-link class="dropdown-item" to="/products/coupons">Coupons</router-link>
                </li>
                <li>
                  <router-link class="dropdown-item" to="/products/affiliate-plan-options">Affiliate Plans</router-link>
                </li>
                <li>
                  <router-link class="dropdown-item" to="/products/plan-options">Plans</router-link>
                </li>
                <li>
                  <router-link class="dropdown-item" to="/products/services-c-corp">Services C-Corp</router-link>
                </li>
                <li>
                  <router-link class="dropdown-item" to="/products/services-fulfillment">Services Fulfillment</router-link>
                </li>
                <li>
                  <router-link class="dropdown-item" to="/products/service-fees">Service Fees</router-link>
                </li>
                <li>
                  <router-link class="dropdown-item" to="/products/service-pricing">Service Pricing</router-link>
                </li>
                <li>
                  <router-link class="dropdown-item" to="/products/service-products">Service Products</router-link>
                </li>
                <li>
                  <router-link class="dropdown-item" to="/products/service-product-plans">Service Product Plans</router-link>
                </li>
                <li>
                  <router-link class="dropdown-item" to="/products/amendment-report-field-config">Amendments & Annual Reports Configuration</router-link>
                </li>
              </ul>
            </li>

            <li class="nav-item dropdown" v-if="authenticated">
              <button type="button" class="btn btn-nav dropdown-toggle" id="navbarAutomationDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                Automation
              </button>
              <ul class="dropdown-menu" aria-labelledby="navbarAutomationDropdown">
                <li>
                  <router-link class="dropdown-item" to="/automation/automation-status">Automation Status</router-link>
                </li>
                <li>
                  <router-link class="dropdown-item" to="/automation/automated-filings">Automated Filings</router-link>
                </li>
                <li>
                  <router-link class="dropdown-item" to="/automation/generate-payload">Generate Payload</router-link>
                </li>
                <li>
                  <router-link class="dropdown-item" to="/automation/retry-callback">Retry Callback</router-link>
                </li>
                <li>
                  <router-link class="dropdown-item" to="/automation/automated-filing-uuid-search">Automated Filing UUID Search</router-link>
                </li>
                <br/>
                <li>
                  <router-link class="dropdown-item" to="/automation/ca-login-access-queue">CA Login Access Queue</router-link>
                </li>
              </ul>
            </li>

            <router-link
              class="btn btn-nav"
              to="/zebra"
              :content="'ZB Registered Agents Correspondence Portal'"
              v-tippy
              v-slot="{ href, navigate, route }">
              <li class="nav-item" v-if="hasZebraPermission()">
                <a :href="href" @click="navigate">{{ route.name }}</a>
              </li>
            </router-link>

            <li class="nav-item dropdown" v-if="(hasPermission('access:developers') || hasPermission('access:internal')) && authenticated && isDevelopment">
              <button type="button" class="btn btn-nav dropdown-toggle" id="navbarToolsDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                Tools
              </button>
              <ul class="dropdown-menu" aria-labelledby="navbarToolsDropdown">
                <li>
                  <a class="dropdown-item" href="/re/admin">
                    Recommendation Admin Tool
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="/fintech/admin">
                    Fintech Admin Tool
                  </a>
                </li>
              </ul>
            </li>

            <form class="form-inline ms-auto" v-if="authenticated" v-on:submit="searchUserByUUID" v-on:submit.prevent v-on:submit.stop>
              <input class="form-control-sm mx-4" v-model="userUuid" placeholder="User UUID"
                size="36" maxlength="36" type="text"
                title="Enter a valid UUID"  pattern="^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$">
            </form>
          </ul>

          <hr class="d-lg-none" />

          <div class="d-flex flex-row justify-content-center justify-content-lg-end">
            <div v-if="authenticated" class="username">{{ username }}</div>
            <div v-if="!authenticated"><router-link class="btn btn-outline-primary my-2 my-sm-0" to="/login">Login</router-link></div>
            <div v-if="authenticated" class="btn btn-outline-primary my-2 my-sm-0" v-on:click="logout()">Logout</div>
          </div>
        </div>
      </div>
    </nav>
    <ZgAlert v-if="successAlert.status" :alert="successAlert" />
    <JiraModal
      @closeJiraModal="closeJiraModal"
      v-if="isJiraModalOpen"/>
    <div class="container-zg">
      <router-view
        :auth="auth"
        :authenticated="authenticated">
      </router-view>
      <button v-on:click="isJiraModalOpen = true" class="jira-btn bg-light" v-if="authenticated">
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_14_1622)">
            <path d="M11 7.338V6C11 4.67392 11.5268 3.40215 12.4645 2.46447C13.4021 1.52678 14.6739 1 16 1C17.3261 1 18.5979 1.52678 19.5355 2.46447C20.4732 3.40215 21 4.67392 21 6V7.338" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M1 28C1 26.9391 1.42143 25.9217 2.17157 25.1716C2.92172 24.4214 3.93913 24 5 24H6.459" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M1 8C1 9.06087 1.42143 10.0783 2.17157 10.8284C2.92172 11.5786 3.93913 12 5 12H6.833" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M1 18H6" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square"/>
            <path d="M31 28C31 26.9391 30.5786 25.9217 29.8284 25.1716C29.0783 24.4214 28.0609 24 27 24H25.541" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M31 8C31 9.06087 30.5786 10.0783 29.8284 10.8284C29.0783 11.5786 28.0609 12 27 12H25.167" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M31 18H26" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square"/>
            <path d="M26 16C26 10.4772 21.5228 6 16 6C10.4772 6 6 10.4772 6 16V21C6 26.5228 10.4772 31 16 31C21.5228 31 26 26.5228 26 21V16Z" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square"/>
          </g>
          <defs>
          <clipPath id="clip0_14_1622">
          <rect width="32" height="32" fill="white"/>
          </clipPath>
          </defs>
        </svg>
        <span>&nbsp;&nbsp;&nbsp;Submit bugs</span>
      </button>
    </div>
  </div>
</template>

<script>
import authService from './auth/AuthService'
import ZgAlert from './components/utils/ZgAlert.vue'
import CircleLogoTeal from '@/components/common/images/CircleLogoTeal.vue';
import {mapGetters, mapState} from 'vuex'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import {hasPermissionMixin, userInformationMixin} from './components/mixins'
import JiraModal from './components/jira/JiraModal.vue'
import router from './router';

const {
  universalLogin, login, logout, authenticated, authNotifier
} = authService;

export default {
  name: 'App',

  components: {
    ZgAlert,
    CircleLogoTeal,
    JiraModal
  },

  mixins: [hasPermissionMixin, userInformationMixin],

  data () {
    authNotifier.on('authChange', authState => {
      this.authenticated = !!authState.authenticated
      this.isAdmin = authState.isAdmin

      if (authService.getAccessToken()) {
        router.replace({ path: '/' })
      } else {
        router.replace({ path: '/login' })
      }

      if (authenticated) {
        const loggedInEmail = authService.getLoggedInEmail();
        if (window.Sentry) {
          window.Sentry.configureScope((scope) => {
            scope.setUser({
              username: loggedInEmail,
              email: loggedInEmail
            });
          });
        }
      } else if (window.Sentry) {
        window.Sentry.configureScope((scope) => {
          scope.setUser(null);
        });
      }
    });
    return {
      auth: authService,
      authenticated,
      isJiraModalOpen: false,
      userUuid: ""
    };
  },

  computed: {
    ...mapGetters({
      getEnvironment: 'system/getEnvironment',
      isDevelopment: 'system/isDevelopment'
    }),
    ...mapState({
      successAlert: (state) => state.successAlert
    }),
    hasUserEmail(){
      return authService.getLoggedInEmail() ? true : false;
    },
    username() {
      if (this.getUserEmail) {
        const nameEmail = authService.getLoggedInEmail().split('@')[0];
        let names = nameEmail.split('.');
        names = names.map((text) => {return text.charAt(0).toUpperCase() + text.slice(1)});
        return names.join(' ');
      }
      return '';
    },
    isUnisearch () {
      return this.hasPermission('partner:unisearch')
    }

  },

  methods: {
    hasZebraPermission() {
      return this.authenticated
        && (this.hasPermission('access:zb-registered-agent') || this.hasPermission('access:developers'));
    },
    resetAlert() {
      this.$store.dispatch('resetAlert');
    },
    testJira() {
      this.fetchJiraUsername();
    },
    universalLogin,
    login,
    logout,
    closeJiraModal() {
      this.isJiraModalOpen = false;
    },
    searchUserByUUID() {
      this.$store.dispatch('users/searchByUUID', { userUuid:this.userUuid });
    }
  }
};
</script>

<style lang="scss">
  @import "~vue-select/src/scss/vue-select.scss";
  $app-font-family: 'Avenir', Helvetica, Arial, sans-serif;
  #app {
    font-family: $app-font-family;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .navbar-brand {
    text-decoration: none !important;
  }
  .container-zg {
    padding: 0 5em;
    width: 100%;
  }
  .stripe-dev {
    color: $clr-white;
    text-shadow: rem(1) rem(1) rem(1) $clr-black;
    background: repeating-linear-gradient(
      45deg,
      $clr-primary-dk,
      $clr-primary-dk 20px,
      $clr-primary-dkr 20px,
      $clr-primary-dkr 40px
    );
  }
  .navbar-nav {
    padding-left: 25px;
    display: flex;
    align-items: center;
  }
  a {
    text-decoration: none;
  }
  h1 {
    font-size: 14pt;
    margin: 0;
  }
  h2 {
    font-size: 10pt;
    margin: 5px 0 0 0;
  }
  pre {
    font-family: $app-font-family;
  }
  .heading {
    padding: 20px 0 0 0;
    a {
      padding: 0;
    }
  }
  .nav-item {
    cursor: pointer;
    a {
      cursor: pointer;
      color: $clr-font-secondary !important;
      text-decoration: none !important;
      transition: color 300ms $easeInOutCubic;
      &:hover {
        color: $clr-font-primary !important;
      }
    }
  }
  h2.header {
    color: #ffffff;
    background-color: #17a2b8;
    width: 100%;
    margin: 10px 0 0;
    padding: 10px 15px;
    border-radius: 5px 5px 0 0;
    box-shadow: 3px 0 3px 0 #f8f8f8;
  }
  h2.muted {
    background-color: #a9a9a9;
  }
  .box {
    border-left: #dee2e6 solid 1px;
    border-right: #dee2e6 solid 1px;
    border-bottom: #dee2e6 solid 1px;
    border-radius: 0 0 5px 5px;
    padding: 15px;
    margin: 0 0 15px 0;
    box-shadow: 3px 3px 3px 0 #f8f8f8;
  }
  .username {
    margin: auto 15px auto 0;
  }

  .modal {
    position: fixed;
    z-index: 1020; // If you need to open a modal within a modal, make this number higher on the nested modal
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
    &-content {
      max-height: calc(100vh - 3.5rem);
      overflow: auto;
    }
  }
  // Custom info btn as the default bootstrap schema changed in the new version 5
  .btn-nav {
    display: block;
    padding: 0.5rem 1rem;
    color: $clr-font-secondary;
    font-weight: 800;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
  }
  .btn-info {
    color:#fff;
    background-color:#17a2b8;
    border-color:#17a2b8
  }
  .btn-info.focus,
  .btn-info:focus,
  .btn-info:hover {
    color:#fff;
    background-color:#138496;
    border-color:#117a8b
  }
  .btn-info.focus,
  .btn-info:focus {
    -webkit-box-shadow:0 0 0 .2rem rgba(58,176,195,.5);
    box-shadow:0 0 0 .2rem rgba(58,176,195,.5)
  }
  .btn-info.disabled,
  .btn-info:disabled {
    color:#fff;
    background-color:#17a2b8;
    border-color:#17a2b8
  }
  .btn-info:not(:disabled):not(.disabled).active,
  .btn-info:not(:disabled):not(.disabled):active,
  .show>.btn-info.dropdown-toggle {
    color:#fff;
    background-color:#117a8b;
    border-color:#10707f
  }
  .btn-info:not(:disabled):not(.disabled).active:focus,
  .btn-info:not(:disabled):not(.disabled):active:focus,
  .show>.btn-info.dropdown-toggle:focus {
    -webkit-box-shadow:0 0 0 .2rem rgba(58,176,195,.5);
    box-shadow:0 0 0 .2rem rgba(58,176,195,.5)
  }

  .jira-btn {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    font-weight: 700;
    height: 2.25rem;
    line-height: 1;
    min-width: 2.25rem;
    padding: 0 0.7rem;
    position: fixed;
    right: 10px;
    bottom: 10px;

    span {
      max-width: 0;
      opacity: 0;
      overflow: hidden;
      transition: all 0.3s ease-in-out;
      white-space: nowrap;
    }

    &:hover {
      span {
        margin-right: 0.3rem;
        max-width: 200px;
        opacity: 1;
      }
    }

    .fas {
      opacity: 0.8;
    }
  }

  .jira-btn {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    font-weight: 700;
    height: 2.25rem;
    line-height: 1;
    min-width: 2.25rem;
    padding: 0 0.7rem;
    position: fixed;
    right: 10px;
    bottom: 10px;

    span {
      max-width: 0;
      opacity: 0;
      overflow: hidden;
      transition: all 0.3s ease-in-out;
      white-space: nowrap;
    }

    &:hover {
      span {
        margin-right: 0.3rem;
        max-width: 200px;
        opacity: 1;
      }
    }

    .fas {
      opacity: 0.8;
    }
  }
</style>
